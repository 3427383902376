
.ProjectPageHeader {
    -webkit-touch-callout: none; 
    /* iOS Safari */ 
    -webkit-user-select: none; 
    /* Chrome/Safari/Opera */ 
    -khtml-user-select: none; 
    /* Konqueror */ 
    -moz-user-select: none; 
    /* Firefox */ 
    -ms-user-select: none; 
    /* Internet Explorer/Edge */ 
    user-select: none;
}

.ProjectPage {
    padding: 20px;
    margin: 20px;

    left: 10%;
    right: 10%;
    border-radius: 10px;

    background-color: #333333;
    box-shadow: 0px 4px #222422;
}
/*
.ProjectPage {
    position: absolute;
}*/

.ProjectPage-enter {
    opacity: 0.01;
}

.ProjectPage-enter-active {
    opacity: 1;
    transition: 500ms;
}

.ProjectPage-exit {
    opacity: 1;
}

.ProjectPage-exit-active {
    opacity: 0.01;
    transition: 500ms;
}



.ProjectPage-Dim {
    padding: 20px;
    margin: 20px;

    left: 10%;
    right: 10%;
    border-radius: 10px;

    background-color: #333533;
    box-shadow: 0px 4px #222422;

    opacity: 0.8;
}
/*
.ProjectPage {
    position: absolute;
}*/

.ProjectPage-Dim-enter {
    opacity: 0.01;
}

.ProjectPage-Dim-enter-active {
    opacity: 0.8;
    transition: 500ms;
}

.ProjectPage-Dim-exit {
    opacity: 0.8;
}

.ProjectPage-Dim-exit-active {
    opacity: 0.01;
    transition: 500ms;
}




.ProjectContent {
  padding: 10px;
}

.ProjectContent-enter {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.ProjectContent-enter-active {
    opacity: 1;
    height: auto;
    overflow: auto;
    top: 0;
    bottom: 0;
    transition: all 300ms;
}

.ProjectContent-exit {
    opacity: 1.0;
    visibility: hidden;
}

.ProjectContent-exit-active {
    opacity: 0;
    height:0;
    overflow: hidden;
    padding:0;
    transition: all 300ms;
}

.ExpandArrow {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 2px;
}