:root {
    --max-pixel-width: 1280px;
  }

.ProjectsList {
    margin: auto;
    max-width: var(--max-pixel-width);
}

.react-markdown-block p {
    padding: 5px;
}
.react-markdown-block hr {
    color: cadetblue;
    opacity: 50%;
    margin: 10px;
}
.react-markdown-block img {
    max-width: min(100%, var(--max-pixel-width));
}

.ProjectPageHeader h2 {
    margin-left: 20%;
    margin-right:20%;
    color: lightsteelblue;
    position: static;
}
.ProjectPageHeader h3 {
    margin-left: 5%;
    margin-right:5%;
    color: lightsteelblue;
    position: static;
}
.ProjectPageHeader hr {
    color: cadetblue;
    opacity: 50%;
    margin: 10px;
}
.ProjectPageHeader .ExpandArrow {
    padding: 10px;
    padding-bottom: 0px;
}

.ProjectDateMarkerActive {
    float: right;
    /* position: relative; */
    background-color: green;
    padding: 4px;
    border-radius: 4px;
}
.ProjectDateMarkerInactive {
    float: right;
    /* position: relative; */
    background-color: #202020;
    padding: 4px;
    border-radius: 4px;
}

.ProjectRepoLink {
    float: left;
    background-color: #202020;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}