/*

Overall scheme:
- background: #888888
- div background: #333333
- text: #eeeeee
- link: #62a388
- link:hover: #b9d2d2
*/

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;

  font-family: Arial, Helvetica, Arial, Helvetica, sans-serif;
  line-height: 1.2;
  color: #eef0ee;
  /*background-color: #333333;*/
}

.App {
  background-color: #889088;
}

body {
  background-color: #889088;
}

a {
  color: #62a388;
  text-decoration: none;
}
a:hover {
  color: #b9d2d2;
  ;
}

ul, ol {
  margin-left: 0.75em;
  text-align: left;
}
